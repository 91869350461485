<template>
  <div class="finalized text-center">
    <section class="d-flex flex-column" style="height: 100%;">
      <div>
        <img class="mb-5" src="@/assets/images/top-page.png" alt="Fórum E-Commerce Brasil 2020" style="max-width: 100%">
      </div>
      <div class="px-4" style="height: 100%; width: 100%;">
        <h1 class="font-weight-normal mb-4" style="color: #ea007f">
          Muito obrigado!
        </h1>
        <h5 class="font-weight-light">Encerramos a transmissão do Fórum E-Commerce Brasil 2020</h5>
        <h5 class="font-weight-light">As palestras autorizadas serão disponibilizadas nos próximos dias no
          <a href="//forumecommerce.com" target="_blank">site do evento</a>.
        </h5>
        <h5 class="font-weight-light">
          Esperamos revê-los em 2021!
        </h5>
      </div>
      <div>
        <img class="mt-auto" src="@/assets/images/bottom-page.jpg" alt="Fórum E-Commerce Brasil 2020" style="max-width: 100%">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Finalized'
}
</script>

<style lang="scss">
.finalized {
  background-color: #041420;
  height: 100vh;
  width: 100%;
  color: #fff;
  a {
    color: #fff;
    font-weight: bold;
    transition: opacity .12s linear;
    &:hover {
      text-decoration: none;
      opacity: .8;
    }
  }
}
</style>
